.dropdown-item {
  display: flex;
  align-items: center;
  &:hover svg path {
    fill: var(--color-brand-primary-base);
  }
}

.margin-right {
  margin-right: 7px;
}

.scrte-search {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid #edf2f7;

  :global(.Dropdown__menu--primary) {
    top: 1.5rem;
    right: 0rem;
    z-index: 7;
  }
}

.asset-count {
  color: var(--color-font-secondary);
  width: 150px;
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-medium);
  text-align: right;
}

.list-icon {
  position: relative;
  display: flex;
  margin-right: 10px;
  border-radius: 3px;
  width: 33px;
  height: 33px;
  background: rgb(245, 245, 245);
  background-size: cover !important;
  justify-content: center;
  align-items: center;
}

:global(.scrte-infinite-list .single-select path) {
  transform: translateY(calc(50% - 8px));
}

.breadcrumb {
  color: #718096;
  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  font-style: 13px;
  font-weight: 500;
  letter-spacing: -6e-5em;
  line-height: 0.9375rem;
  padding: 17px 9px 17px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edf2f7;
}

.mr-5 {
  margin-right: 5px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  &:hover svg path {
    fill: var(--color-brand-primary-base);
  }
}

.margin-right {
  margin-right: 7px;
}

.scrte-search {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6px 10px;
  border-bottom: 1px solid #edf2f7;

  :global(.Dropdown__menu--primary) {
    top: 1.5rem;
    right: 0rem;
    z-index: 7;
  }
}

.asset-count {
  color: var(--color-font-secondary);
  width: 150px;
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-medium);
  text-align: right;
}

#scrollableDiv .AssetCard {
  margin: 0 auto 1.25rem auto;
}

#scrollableDiv {
  margin-top: 1.25rem;
}

