#vod-custom-field{
    margin:15px;
}

#vod-custom-field #view-video .AssetCard.wo-dimensions    {
    width: 321px;
}

.play-icon{
    position: absolute;
    top: 40px;
    left: 140px;
}