#asset-info-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    width: 100%;
    overflow: hidden;
    grid-template-rows: auto 1fr;
}

.video-url{
    grid-column: span 2;
    margin-top: 25px;
    width: 90%;
}

.video-embed{
  grid-column: span 2;
  width: 90%;
}

.video-embed p , .video-url p {
  width: 95%;
} 

.video-url .tippy-wrapper, .video-embed .tippy-wrapper{
    display: block;
    float: right;
    margin-top: -22px;
}

  :root {
    --gutter: 30px;
  }
  
  #wrapper .thumbnail-lable{
    margin-top: 20px;
  }
  .thumbnail-wrapper {
    padding: 15px 0;
    display: grid;
    grid-gap: var(--gutter) 0;
    grid-template-columns: var(--gutter) 1fr var(--gutter);
    align-content: start;
  }
  
  .thumbnail-wrapper > * {
    grid-column: 2 / -2;
  }
  
  .thumbnail-wrapper > .full {
    grid-column: 1 / -1;
  }
  
  .thumbnail-list {
    height: 170px;
    overflow: hidden;
    display: grid;
    /* grid-gap: calc(var(--gutter) / 2); */
    grid-template-columns: 10px;
    grid-template-rows: minmax(100px, 1fr);
    grid-auto-flow: column;  
    overflow-x: hidden;
    scroll-snap-type: x proximity;
    padding-bottom: 5px;
    margin-bottom: calc(-.25 * var(--gutter));
  }
  
  .thumbnail-list:before,
  .thumbnail-list:after {
    content: '';
    width: 10px;
  }

  .thumbnail-list .AssetCard, .thumbnail-list .AssetCard__asset.AssetCard__asset--image{
    width: 150px;
    height: 112px;
  }

  .thumbnail-wrapper .AssetCard.wo-dimensions .AssetCard__shortinfo{
    bottom: -34px !important;
  }

  .thumbnail-wrapper .AssetCard:not(.AssetFolder):hover:before{
    height: 160px !important;
  }

  .modal-buttons{
    margin: 0px 28px 11px 28px;
  }

  .ReactModalPortal .ReactModal__Overlay {
    align-items: end; 
  }

  .resolutions-value{
    background: #EDF1F7;
    border-radius: 4px;
    padding: 5px;
    margin-right:10px ;
  }

  .modal-buttons .AsyncLoader--dot{
    background-color: #6c5ce7 !important;
  }

  .resolution-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
    width: 100%;
    align-items: center;
    text-align: center;
  }