#wrapper{
    padding: 30px 20px;
    width: 100%;
    height: 100%;
}


#wrapper .text-value{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 185%;
    margin: 10px 0.625rem 0.125rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.EmptyState--primary{
    margin-top: 200px;
}

div .EmptyStateWrapper .EmptyState--primary .EmptyState__title ,  .EmptyState__description {
    margin-top: 20px;
}
