#assert-data-wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 20px;
    width: 100%;
    overflow: hidden;
}

#create-video-wrapper{
    padding-top: 70px;
    display: flex;
    align-items: center;
    flex-flow: column ;
    gap: 25px;
}

#create-video-wrapper h2{
    color: '#6C5CE7';
}

#create-video-wrapper p{
    padding: 2px 10px;
    text-align: center;
    line-height: 22px;
}

.resolution-select{
    align-self: baseline;
    width: 100%;
}

#create-video-wrapper > div > div > div > div.Select__menu.css-26l3qy-menu .css-1jqbu51-MenuList{
    height: 130px;
}

#create-video-wrapper .CSLogoPreloaderContainer{
    margin-bottom: 0px;
}